import React from 'react'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Newsletter from '../../components/Newsletter'

const ManufacturersSignUpPage = () => {
  return (
    <Page className="p-formSuccess has-fingerPrints">
      <SEO title="Success! - Sign up form submitted!" />

      <section className="c-section c-section--pageHeader">
        <div className="c-section__outer container">
          <div className="c-section__inner pb-3">
            <div className="c-pageHeader">
              <div className="row">
                <div className="col-12 col-sm-7 col-md-9">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <span className="c-pageHeader__title">
                        <strong>Demo</strong> sign up
                      </span>
                    </div>
                    <div className="col-12 col-lg-8">
                      <h1 className="c-pageHeader__intro">
                        <strong>Success!</strong>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section c-section--contact-us">
        <div className="c-section__outer container">
          <div className="c-section__inner pt-0 pb-1">
            <div className="c-sectionTitle">
              <div className="row">
                <div className="col-12 col-lg-7 offset-lg-3">
                  <p>
                    Thank you, your message has been sent and we will be in
                    touch shortly!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default ManufacturersSignUpPage
